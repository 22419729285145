import { ACTION_TYPES } from "../actions/leads";

const defaultState = {
  list: null,
  isLoading: false,
  error: null
};

const leads = (state = defaultState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_LEADS_INIT:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case ACTION_TYPES.FETCH_LEADS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        isLoading: false,
        error: null
      };

    case ACTION_TYPES.FETCH_LEADS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    default:
      return state;
  }
};

export default leads;
