import axios from "axios";

export const ACTION_TYPES = {
  FETCH_LEADS_INIT: "FETCH_LEADS_INIT",
  FETCH_LEADS_SUCCESS: "FETCH_LEADS_SUCCESS",
  FETCH_LEADS_ERROR: "FETCH_LEADS_ERROR"
};

export const fetchLeads = () => {
  return (dispatch, getState) => {
    const uid = getState().user.authorizedUser.uid;
    const idToken = getState().user.idToken;

    dispatch({
      type: ACTION_TYPES.FETCH_LEADS_INIT
    });

    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
    axios.headers = { Authorization: `${idToken}` };
    return axios
      .get(`/v1/leads/${uid}?type=impact`)
      .then(result => {
        dispatch({
          type: ACTION_TYPES.FETCH_LEADS_SUCCESS,
          payload: result.data.data
        });
      })
      .catch(error => {
        dispatch({
          type: ACTION_TYPES.FETCH_LEADS_ERROR,
          error: error
        });
      });
  };
};
