import axios from "axios";

export const ACTION_TYPES = {
  FETCH_METERS_INIT: "FETCH_METERS_INIT",
  FETCH_METERS_SUCCESS: "FETCH_METERS_SUCCESS",
  FETCH_METERS_ERROR: "FETCH_METERS_ERROR"
};

export const fetchMeters = selectedDate => {
  return (dispatch, getState) => {
    const uid = getState().user.authorizedUser.uid;
    const idToken = getState().user.idToken;

    const query = getListFetchQueries(selectedDate);

    dispatch({
      type: ACTION_TYPES.FETCH_METERS_INIT,
      selectedDate
    });

    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
    axios.headers = { Authorization: `${idToken}` };
    return axios
      .get(`/v1/earnings/${uid}`, { params: query })
      .then(result => {
        dispatch({
          type: ACTION_TYPES.FETCH_METERS_SUCCESS,
          payload: result.data.data,
          selectedDate
        });
      })
      .catch(error => {
        dispatch({
          type: ACTION_TYPES.FETCH_METERS_ERROR,
          error: error
        });
      });
  };
};

const getListFetchQueries = selectedDate => {
  if (!selectedDate) {
    return null;
  }

  let [startDate, endDate] = selectedDate.value.split("-");
  startDate = startDate.replace(/\//g, "-");
  endDate = endDate.replace(/\//g, "-");

  return { startDate, endDate };
};
