import React, { Component } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import Select from "react-select";

class Appointments extends Component {
  componentDidMount() {
    const { isLoading, error, list, fetchAppointments } = this.props;

    if (!isLoading && error === null && list === null) {
      const dateOptions = this.getDateOptions(2022, 1).reverse();
      const selectedDate =
        new Date().getDate() > 15 ? dateOptions[0] : dateOptions[1];
      fetchAppointments(selectedDate);
    }
  }

  render() {
    const { list, isLoading, error, selectedDate } = this.props;
    const dateOptions = this.getDateOptions(2022, 1).reverse();
    return (
      <div>
        <div style={{ width: "17vw" }}>
          <Select
            name="date"
            placeholder="Select Date"
            label="Date"
            isSearchable={true}
            isClearable={true}
            options={dateOptions}
            value={selectedDate}
            onChange={option => this.dateChangeHandler(option)}
          />

          {list ? (
            <div className="first-payment">
              <label>Total Earnings: </label>
              <h4>
                {list
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue.earning,
                    0
                  )
                  .toFixed(2)}
              </h4>
            </div>
          ) : null}
        </div>
        {!isLoading && list !== null && error === null ? (
          <div>
            <ReactTable
              data={list}
              columns={this.getTableColumns()}
              pageSizeOptions={[20, 25, 50]}
              style={{ height: "84vh" }}
            />
          </div>
        ) : (
          <div className="loading">
            <div className="loader"></div>
            <div className="loader-text">Loading...</div>
          </div>
        )}
      </div>
    );
  }

  dateChangeHandler = selectedDate => {
    const { fetchAppointments } = this.props;
    fetchAppointments(selectedDate);
  };

  getDateOptionsFormattedDate = date => {
    const year = date.getFullYear();
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();

    month = month.length < 2 ? "0" + month : month;
    day = day.length < 2 ? "0" + day : day;

    return [year, month, day].join("/");
  };

  getDateOptions = (year, month) => {
    let result = [];
    const currentMonth = new Date().getMonth() + 1;
    const numberOfDays = new Date(year, month, 0, 0, 0).getDate();

    result.push({
      label: [
        this.getDateOptionsFormattedDate(new Date(year, month - 1, 1)),
        this.getDateOptionsFormattedDate(new Date(year, month - 1, 15))
      ].join("-"),
      value: [
        this.getDateOptionsFormattedDate(new Date(year, month - 1, 1)),
        this.getDateOptionsFormattedDate(new Date(year, month - 1, 15))
      ].join("-")
    });

    result.push({
      label: [
        this.getDateOptionsFormattedDate(new Date(year, month - 1, 16)),
        this.getDateOptionsFormattedDate(
          new Date(year, month - 1, numberOfDays)
        )
      ].join("-"),
      value: [
        this.getDateOptionsFormattedDate(new Date(year, month - 1, 16)),
        this.getDateOptionsFormattedDate(
          new Date(year, month - 1, numberOfDays)
        )
      ].join("-")
    });

    if (month < currentMonth) {
      result = result.concat(this.getDateOptions(year, month + 1));
    }

    return result;
  };

  getTotalAppointments = () => {
    // will get it from backend
  };

  getTableColumns = () => {
    const columns = [
      {
        id: "Appointments",
        Header: " Validated Appointments",
        accessor: null,
        columns: [
          {
            id: "Name",
            Header: "Name",
            accessor: "name",
            filterable: true
          },
          {
            id: "Email",
            Header: "Email",
            accessor: "email",
            filterable: true
          },
          {
            id: "Date",
            Header: "Date",
            accessor: "date",
            filterable: false
          },
          {
            id: "Outcome",
            Header: "Outcome",
            accessor: "outcome"
          },
          {
            id: "Earning",
            Header: "Earning",
            accessor: item => `$${item.earning}`
          }
        ]
      }
    ];

    return columns;
  };

  convertToLocaleDate = value => {
    if (!value) {
      return null;
    }

    return new Date(value).toLocaleDateString(
      "en-US",
      { timeZone: "UTC" },
      {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      }
    );
  };
}

export default Appointments;
