import { combineReducers } from "redux";
import user from "./user";
import meters from "./meters";
import leads from "./leads";
import appointments from "./appointments";

export default combineReducers({
  user,
  meters,
  leads,
  appointments
});
