import { ACTION_TYPES } from "../actions/meters";

const defaultState = {
  list: null,
  isLoading: false,
  error: null,
  selectedDate: null
};

const meters = (state = defaultState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_METERS_INIT:
      return {
        ...state,
        selectedDate: action.selectedDate,
        isLoading: true,
        error: null
      };

    case ACTION_TYPES.FETCH_METERS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        selectedDate: action.selectedDate,
        isLoading: false,
        error: null
      };

    case ACTION_TYPES.FETCH_METERS_ERROR:
      return {
        ...state,
        selectedDate: null,
        isLoading: false,
        error: action.error
      };

    default:
      return state;
  }
};

export default meters;
