import Meters from "../../components/meters";
import { fetchMeters } from "../../actions/meters";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  return {
    list: state.meters.list,
    isLoading: state.meters.isLoading,
    error: state.meters.error,
    selectedDate: state.meters.selectedDate
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchMeters: data => dispatch(fetchMeters(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Meters);
