import Appointments from "../../components/appointments";
import { fetchAppointments } from "../../actions/appointments";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  return {
    list: state.appointments.list,
    isLoading: state.appointments.isLoading,
    error: state.appointments.error,
    selectedDate: state.appointments.selectedDate
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAppointments: data => dispatch(fetchAppointments(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
