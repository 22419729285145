import React, { Component } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import Select from "react-select";

class meters extends Component {
  componentDidMount() {
    console.log("within th componnet did mount metrs");
    const { isLoading, error, list, fetchMeters } = this.props;

    if (!isLoading && error === null && list === null) {
      const dateOptions = this.getDateOptions(2022, 1).reverse();
      const selectedDate =
        new Date().getDate() > 15 ? dateOptions[0] : dateOptions[1];
      fetchMeters(selectedDate);
    }
  }

  render() {
    console.log("within the render for meters componenent");
    const { list, isLoading, error, selectedDate } = this.props;
    console.log(this.props);
    const dateOptions = this.getDateOptions(2022, 1).reverse();

    return (
      <div>
        <div style={{ width: "17vw" }}>
          <Select
            name="date"
            placeholder="Select Date"
            label="Date"
            isSearchable={true}
            isClearable={true}
            options={dateOptions}
            value={selectedDate}
            onChange={option => this.dateChangeHandler(option)}
          />

          {list ? (
            <div className="first-payment">
              <label>Total Earnings: </label>
              <h4>
                $
                {list
                  .reduce((accumulator, currentValue) => accumulator + 35.0, 0)
                  .toFixed(2)}
              </h4>
            </div>
          ) : null}
        </div>
        {!isLoading && list !== null && error === null ? (
          <div>
            <ReactTable
              data={list}
              columns={this.getTableColumns()}
              pageSizeOptions={[20, 25, 50]}
              style={{ height: "84vh" }}
            />
          </div>
        ) : (
          <div className="loading">
            <div className="loader"></div>
            <div className="loader-text">Loading...</div>
          </div>
        )}
      </div>
    );
  }

  dateChangeHandler = selectedDate => {
    const { fetchMeters } = this.props;
    fetchMeters(selectedDate);
  };

  getDateOptionsFormattedDate = date => {
    const year = date.getFullYear();
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();

    month = month.length < 2 ? "0" + month : month;
    day = day.length < 2 ? "0" + day : day;

    return [year, month, day].join("/");
  };

  getDateOptions = (year, month) => {
    let result = [];
    const currentMonth = new Date().getMonth() + 1;
    const numberOfDays = new Date(year, month, 0, 0, 0).getDate();

    result.push({
      label: [
        this.getDateOptionsFormattedDate(new Date(year, month - 1, 1)),
        this.getDateOptionsFormattedDate(new Date(year, month - 1, 15))
      ].join("-"),
      value: [
        this.getDateOptionsFormattedDate(new Date(year, month - 1, 1)),
        this.getDateOptionsFormattedDate(new Date(year, month - 1, 15))
      ].join("-")
    });

    result.push({
      label: [
        this.getDateOptionsFormattedDate(new Date(year, month - 1, 16)),
        this.getDateOptionsFormattedDate(
          new Date(year, month - 1, numberOfDays)
        )
      ].join("-"),
      value: [
        this.getDateOptionsFormattedDate(new Date(year, month - 1, 16)),
        this.getDateOptionsFormattedDate(
          new Date(year, month - 1, numberOfDays)
        )
      ].join("-")
    });

    if (month < currentMonth) {
      result = result.concat(this.getDateOptions(year, month + 1));
    }

    return result;
  };

  getTotalFirstPayment = () => {
    // will get it from backend
  };

  getTableColumns = () => {
    const { list } = this.props;
    const firstPayment = list
      .reduce((accumulator, currentValue) => accumulator + 35.0, 0)
      .toFixed(2);

    const columns = [
      {
        id: "MyEarnings",
        Header: "Enrolled Customers",
        accessor: item => item.EarningSummary__r.Name,
        columns: [
          {
            id: "Name",
            Header: "Name",
            accessor: item => item.Customer__r.Name,
            filterable: true
          },
          {
            id: "CreatedDate",
            Header: "Converted Date",
            accessor: item => this.convertToLocaleDate(item.CreatedDate)
          },
          {
            id: "AssugbedKwh",
            Header: "Assigned kWh",
            accessor: item => item.Customer__r.Account.TargetAllocatedkWh__c
          },
          {
            id: "Project_Name",
            Header: "Project",
            accessor: item => item.Customer__r.Account.Project__c
          },
          {
            Header: "Contributed to Charity",
            Cell: <span>$20.00</span>
          },
          {
            Header: (
              <label>
                Total Earnings: <strong>${firstPayment}</strong>
              </label>
            ),
            Cell: <span>$35.00</span>

            // accessor: "Rate__c",
            // Cell: props => <span>${props.value.toFixed(2)}</span>
          }
        ]
      }
    ];

    return columns;
  };

  convertToLocaleDate = value => {
    if (!value) {
      return null;
    }

    return new Date(value).toLocaleDateString(
      "en-US",
      { timeZone: "UTC" },
      {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      }
    );
  };
}

export default meters;
